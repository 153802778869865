#userTable {
  border-collapse: collapse;
  width: 100%;
}

#userTable td {
  border: 1px solid #c9c5c5;
  background-color: #f2f2f2;
  padding: 8px;
  text-align: left;
}

#userTable th {
  background-color:#f0fff0e0;
  border: 1px solid #dddddd;
  padding: 8px;
}

.connect-button {
    background-color: #566D7E;
    color: white;
  }

  .hidden {
    display: none;
  }

  .connect-button:disabled {
    /* Grey out the disabled buttons */
    opacity: 0.5; /* Adjust opacity as needed */
    cursor: not-allowed; /* Change cursor to indicate disabled state */
  }
