.page-container {
    display: flex;
  }
  
  .left-pane {
    flex: .105;
    padding: 20px;
    background-color: #8D918D;
  }

  .tile {
    margin-bottom: 20px;
    padding: 10px;
    border: 1px solid #5075c788;
    background-color: #f0fff0e0;
  }
  
  
  .right-pane {
    flex: 1;
    padding: 20px;
    background-color: #e0e0e0;
  }