/* RegistrationForm.css */

form {
    max-width: 400px;
    margin: 0 auto;
  }
  
  label {
    display: block;
    margin-bottom: 8px;
  }
  
  input {
    width: 100%;
    padding: 8px;
    margin-bottom: 16px;
    box-sizing: border-box;
  }
  
  button {
    background-color: #4caf50;
    color: white;
    padding: 10px 15px;
    border: none;
    border-radius: 4px;
    cursor: pointer;
  }
  
  button:hover {
    background-color: #45a049;
  }

  .registration-container {
    display: flex;
    flex-direction: column;
    align-items: flex-end; /* Align items to the right */
}

.logo {
    width: 100px; /* adjust the width as needed */
    height: auto; /* maintain aspect ratio */
    margin-bottom: 20px; /* adjust the margin as needed */
}