.center-text {
  text-align: center;
  margin-top: 20px; /* Adjust the margin-top as needed */
}

.logo {
  width: 100px; /* adjust the width as needed */
  height: auto; /* maintain aspect ratio */
  margin-bottom: 20px; /* adjust the margin as needed */
}

body {
  color: #696767; /* Set the text color to white */
}

.header {
  display: flex;
  justify-content: right;
  align-items: center;
  padding: 10px;
  background-color: #eee; /* Add your desired background color */
}

.auth-links {
  display: flex;
  align-items: center;
  margin-right: 10px;
}

.register-links {
  text-align: left;
  align-items: left;
  margin-right: 10px;
}

.version-label {
  margin-right: auto; /* This will push the version label to the left */
}